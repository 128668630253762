import request from '@/utils/request'

/**
 * @description 上传
 * @param {Object} param data {Object} 传值参数
 */
export function getOssConfig (configId) {
  return request({
    url: '/common/get-cos-signature',
    method: 'get'
  })
}


// export function getTenCosConfig (configId) {
//   return request({
//     url: '/common/get-vueOss-signature',
//     method: 'get'
//   }).then(function (res) {
//     return res.data;
//   })
// }

export function getTenCosConfig() {
  // return axios.post(url).then(function (response) {
  //   return response.data.signature;
  // })
  return request({
    url: '/common/get-vueOss-signature',
    method: 'get'
  }).then(function (res) {
    return res.data;
  })
};
